import Two454 from "../assets/twofourLogo.jpeg";
import abuDhabiMedia from "../assets/abudhabiMediaLogo.svg";
import accenture from "../assets/accenture-logo.png";
import arabianAdventure from "../assets/arabianAdvLogo.png";
import asianSchool from "../assets/asianschool.jpg";
import intlIndianSchool from "../assets/intlIndianSchool.png";
export const clients = [
  {
    label: "Two454",
    image: Two454,
  },
  {
    label: "abuDhabiMedia",
    image: abuDhabiMedia,
  },
  {
    label: "accenture",
    image: accenture,
  },
  {
    label: "arabianAdventure",
    image: arabianAdventure,
  },
  {
    label: "asianSchool",
    image: asianSchool,
  },
  {
    label: "intlIndianSchool",
    image: intlIndianSchool,
  },
];
